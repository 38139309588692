<template>
  <div class="my-2">
    <div class="cursor-pointer">
      <div @click="openExportModal" class="flex available-action px-4 py-2">
        <span class="material-icons text-lg self-center">upload_file</span>
        <span class="text-sm ml-2">{{ $t('Export') }}</span>
      </div>
    </div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeExportModal"
      :active.sync="exportModal"
      :title="$t('Export')"
      :buttons-hidden="true">
      <div class="text-center mt-1">
        <div v-if="this.$route.name !== 'merchant-account-financials' && this.$route.name !== 'head-of-fleet-in-house-orders'" class="mb-4">
          <p class="text-darkgray text-base mb-4">{{ $t('Select orders statuses you want to export!') }}</p>
          <div class="clearfix flex justify-center">
            <div v-for="(criteria, index) in criterias" :key="index">
              <label @click="checkCriteria(criteria)" class="prompt cursor-pointer whitespace-nowrap">
                <input v-model="criteriaName" :class="criteria.checked ? 'checked' : ''" :value="criteria.value" type="radio" />
                <span class="py-2 px-4 rounded mx-1">{{ criteria.name }}</span>
              </label>
            </div>
          </div>
        </div>
        <div v-if="$store.state.AppActiveUser.userRole === 'sales'" class="max-w-sm m-auto my-4">
          <v-select v-validate="'required'" name="merchant" v-model="merchant" :placeholder="$t('Merchant')" label="name" :options="merchants"/>
          <span class="text-danger text-sm" v-show="errors.has('merchant')">{{ errors.first('merchant') }}</span>
        </div>
        <div v-if="this.$route.name === 'merchant-account-financials'"  class="flex items-center justify-center mb-4">
          <vs-radio class="mr-4" v-model="exportFormat" vs-value="excel">{{$t('Excel')}}</vs-radio>
          <vs-radio v-model="exportFormat" vs-value="pdf">{{$t('PDF')}}</vs-radio>
        </div>
        <p class="text-darkgray">{{ $t('Select your export range!') }}</p>
        <div class="mt-4 md:flex block gap-4 justify-center">
          <div class="flex justify-center">
            <datepicker :inline="true" name="from" :disabledDates="disabledDatesStart" v-model="startDate"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('from')">{{ errors.first('from') }}</span>
          </div>
          <div class="flex justify-center md:mt-0 mt-4">
            <datepicker :inline="true" name="to" :disabledDates="disabledDates" v-model="endDate"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('to')">{{ errors.first('to') }}</span>
          </div>
        </div>
        <div class="flex justify-evenly mt-3">
          <p class="text-blue-900 date-border p-2">{{startDate ? common.gettingDate(new Date(startDate).toISOString()) : $t('No Date')}}</p>
          <div class="flex" :style="$i18n.locale === 'ar' ? 'transform: rotate(180deg);' : ''">
            <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
            <img class="w-3" src="@/assets/images/pages/arrow.svg" alt="">
          </div>
          <p class="text-blue-900 date-border p-2">{{endDate ? common.gettingDate(new Date(endDate).toISOString()) : $t('No Date')}}</p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeExportModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="sendExport" :class="((this.$route.name === 'merchant-account-financials' || this.$route.name === 'head-of-fleet-in-house-orders') && startDate) ? 'active-btn' : (startDate && criteriaName) ? 'active-btn' : 'disable-btn pointer-events-none'" class="btn">{{ $t('Export') }}</button>
        </div>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import i18nData from '../../../i18n/i18nData.js'

export default {
  data () {
    return {
      common,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      disabledDates: {
        to: new Date() // Disable all dates up to specific date
      },
      disabledDatesStart: {
        from: new Date() // Disable all dates up tscrollbarTago specific date
      },
      startDate: '',
      endDate: '',
      exportModal: false,
      criteriaName: '',
      criteria: '',
      criterias: [
        {
          name: 'Created',
          value: 'created',
          checked: false
        },
        {
          name: 'Picked Up',
          value: 'picked_up',
          checked: false
        },
        {
          name: 'Completed',
          value: 'completed',
          checked: false
        }
      ],
      exportFormat: 'excel',
      localStorage,
      merchants: [],
      merchant: '',
      merchantId: ''
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  watch: {
    'exportModal' () {
      this.disabledDatesStart = {
        from: new Date() // Disable all dates up tscrollbarTago specific date
      }
    },
    'startDate' () {
      const date = new Date(this.startDate)
      this.endDate = this.startDate ? new Date(this.startDate) : ''
      this.disabledDates.to = date
      const endDate = new Date(this.startDate)
      this.disabledDates.from = this.$store.state.AppActiveUser.userRole === 'merchant' ? new Date(endDate.setDate(date.getDate() + 30)) : new Date(endDate.setDate(date.getDate() + 7))
    }
  },
  methods: {
    checkCriteria (criteria) {
      this.criterias.forEach(item => {
        if (item.name === criteria.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    loadAllMerchants () {
      sendRequest(false, false, this, 'api/v1/merchants/', 'get', null, true,
        (response) => {
          this.merchants = response.data.results
        }
      )
    },
    openExportModal () {
      this.$validator.resume()
      this.exportModal = true
    },
    closeExportModal () {
      this.exportFormat = 'excel'
      this.exportModal = false
      this.criteriaName = ''
      this.startDate = ''
      this.endDate = ''
      this.criterias.forEach(item => {
        item.checked = false
      })
    },
    sendExport () {
      this.$validator.validateAll().then(result => {
        if (result) {
          let url = ''
          if (this.$route.name === 'merchant-account-financials') {
            url = `api/v1/analytics/invoices/merchant/${this.merchantId ? this.merchantId : this.merchant.id}/export/?`
          } else if (this.$store.state.AppActiveUser.userRole === 'merchant' || this.$store.state.AppActiveUser.userRole === 'sales') {
            url = `api/v1/analytics/orders/merchant/${this.merchantId ? this.merchantId : this.merchant.id}/export/?`
          } else if (this.$route.name === 'head-of-fleet-in-house-orders') {
            url = `api/v1/analytics/orders/export/?current_warehouse=${this.$route.params.warehouseID}&status=in_transit`
          } else if (localStorage.getItem('multipleWarehouses') === 'false') {
            const query = this.$route.name === 'head-of-fleet-first-mile-orders' || this.$route.name === 'head-of-fleet-returns' ? `inbound_warehouse=${this.$route.params.warehouseID}&` : this.$route.name === 'head-of-fleet-orders'  ? `outbound_warehouse=${this.$route.params.warehouseID}` : ''
            url = `api/v1/analytics/orders/export/?${query}`
          } else {
            const query = localStorage.getItem('multipleWarehouses') === 'false' ? `warehouse=${this.$route.params.warehouseID}` : ''
            url = `api/v1/analytics/orders/export/?${query}`
          }
          sendRequest(false, false, this, `${url}&export_format=${this.exportFormat}&criteria=${this.criteriaName}&from=${new Date(this.startDate).toISOString().split('T')[0]}&to=${new Date(this.endDate).toISOString().split('T')[0]}`, 'get', null, true, 
            (response) => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text: response.data.message,
                position: 'top-center'
              })
              this.closeExportModal()
            }
          )
        }
      })
    }
  },
  components: {
    Datepicker,
    vSelect,
    ShipbluPrompt
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let userMerchant = {}
      merchantData.then(results => {
        userMerchant = results.userMerchantData
        this.merchantId = userMerchant.merchant
      })
    }
    if (this.$store.state.AppActiveUser.userRole === 'sales') {
      this.loadAllMerchants()
    }
  }
}
</script>

<style lang="scss" scoped>
.text-darkgray {
  color: #475C6F;
}
.date-border {
  border-bottom: 0.6px solid #9DADC2;
}
.btn {
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 18px;
} 
.disable-btn {
  background: #F1F3F6;
  color: #6C84A3;
}
.active-btn {
  color: #fff;
  font-weight: 500;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
</style>