<template>
  <div class="my-2 relative">
    <div class="cursor-pointer vs-con-loading__container relative" id="print-btn-loading">
      <div class="available-action flex pl-4 pr-10 py-2" @click="printLabels()">
        <feather-icon icon="PrinterIcon" svgClasses="w-4 h-4" class="self-center"/>
        <span class="text-sm ml-2">{{ $t('Print Label') }}</span>
      </div>
      <div class="print-dropdown available-action flex items-center" @click="showPrintDropdown = !showPrintDropdown">
        <span class="material-icons-outlined">arrow_drop_down</span>
      </div>
    </div>
    <div v-show="showPrintDropdown" class="dropdown py-1 px-4 rounded text-blue-900">
      <p @click="printLabels('&one_per_page=true')" class="cursor-pointer text-sm hover:text-primary">{{ $t('One per page') }}</p>
      <p v-if="$store.state.AppActiveUser.userRole === 'merchant' && $route.name === 'merchant-shipments'" @click="printLabels('&status=pickup_requested')" class="cursor-pointer mt-2 text-sm hover:text-primary">{{ $t('Pickup requested') }}</p>
      <p v-if="$store.state.AppActiveUser.userRole === 'merchant' && $route.name === 'merchant-shipments'" @click="printLabels('&status=pickup_rescheduled')" class="cursor-pointer mt-2 normal-case text-sm hover:text-primary">{{ $t('Pickup rescheduled') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import JsBarcode from 'jsbarcode'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['selected'],
  data () {
    return {
      selectedFlag: false,
      showPrintDropdown: false
    }
  },
  watch: {
    selected (val) {
      this.showPrintDropdown = false
      if (val.length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    printLabels (query) {
      this.showPrintDropdown = false
      const dataFromFrontEnd = []
      const dataToBackEnd = []
      this.selected.forEach(element => {
        if (element.name || element.tracking_number[0] === '4') {
          dataFromFrontEnd.push(element)
        } else {
          dataToBackEnd.push(element) 
        }
      })
      if (dataToBackEnd.length > 0 || (query && query.includes('status'))) {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: '#print-btn-loading',
          scale: 0.45
        })
        axios({
          url: `${process.env.VUE_APP_API_URL}/api/v1/orders/shipping-label/?type=pdf&tracking_numbers=${dataToBackEnd.map(item => item.tracking_number)}${query ? query : ''}`,
          method: 'GET',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }).then((response) => {
          if (query && query.includes('status')) {
            this.$vs.notify({
              color:'success',
              title: i18nData[this.$i18n.locale]['Success'],
              text: i18nData[this.$i18n.locale]['we will generate orders label and sent to you notification after finish it to download it.'],
              position: 'top-center'
            }) 
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'ShipBlu AWBs.pdf')
            document.body.appendChild(link)
            link.click()
          }
          this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
        }).catch((error) => {
          if (error && error.response && error.response.status === 400 && error.response.status) {
            this.$vs.notify({
              color:'danger',
              title: i18nData[this.$i18n.locale]['Failed'],
              text: i18nData[this.$i18n.locale]['Cannot generate a shipping label for unzoned orders.'],
              position: 'top-center'
            })
          }
          this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
        })
      }
      if (dataFromFrontEnd.length > 0) {
        this.selected = dataFromFrontEnd
        JsBarcode('.barcode').init()
        window.print()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.print-dropdown {
  position: absolute;
  top: calc(50% - 12px);
  right: 4px;
  user-select: none;
}
.dropdown {
  position: absolute;
  z-index: 991;
  top: 40px;
  background: #fff;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.32);
  border: 1px solid rgba(0,0,0,.1);
  user-select: none;
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 6px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 7.5px solid #fff;
    pointer-events: none;
  }
}
</style>